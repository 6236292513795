import { EventDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<EventDistributorProps> = {
	schemaType: "module",
	component: "EventDistributor",
	category: "distributors",
	displayName: "EventDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },

				{ ...detail },

				{
					title: "What events do you want to show?",
					type: "RadioGroup",
					key: "showUpcomingEvents",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Upcoming Events",
							name: "upcoming",
						},
						{
							value: false,
							title: "Past Events",
							name: "past",
						},
					],
				},

				{
					type: "ReferenceField",
					title: "Events",
					sources: [{ structuredData: "EVENTS" }],
					selectionType: ["auto", "manual"],
					key: "data",
				},

				{
					type: "CheckGroup",
					key: "distributorOptions",
					title: "Distributor Options",
					options: [
						{ name: "search", title: "Search" },
						{ name: "filters", title: "Filters" },
					],
				},

				{
					type: "ReferenceField",
					title: "Event Categories",
					helptext: "Select the categories you want to show in filters",
					sources: [{ structuredData: "EVENTS_CATEGORY" }],
					selectionType: ["manual"],
					key: "eventCategoryFilters",
				},

				{
					type: "TextField",
					key: "noResultsText",
					title: "No result text",
					mandatory: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "EventDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		showUpcomingEvents: true,
		data: {
			mode: "auto",
			order: "eventDate-ASC",
			quantity: 0,
			sources: [{ structuredData: "EVENTS" }],
		},
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		distributorOptions: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/EventDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/EventDistributor/thumbnail@2x.png",
	},
};

export default schema;
