const en_GB = {
	global: {
		skipToContent: "Skip to content",
		readMore: "Read more",
		knowMore: "View more",
		download: "Download",
		new: "New",
		search: "Search",
		filter: "Filter",
		playVideo: "Play video",
		close: "Close",
		siteWeb: "Website",
		cookie_settings: "Cookie Settings",
		removeFilters: "Remove filters",
	},
	languages: {
		short: {
			en: "ENG",
			es: "ESP",
		},
		long: {
			en: "ENGLISH",
			es: "SPANISH",
		},
	},
	form: {
		dont_results: "No options available",
		select_i_am: {
			student: "Student",
			legal_tutor: "Father / Mother / Legal guardian",
			teacher: "Teacher / Counsellor",
			other: "Other (specify)",
		},
		genre: {
			female: "Female",
			male: "Male",
			other: "I prefer not to say",
		},
		beginning_date_options: {
			"2025-26": "Academic year 2025-2026",
			other: "Another",
		},
		i_am: "I am...",
		i_am_other: 'Specify "Other".',
		placeholder_i_am_other: 'Specify "Other".',
		placeholder_i_am: "Select an option",
		name: "Name",
		placeholder_name: "Name",
		lastname: "Lastname",
		placeholder_lastname: "Lastname",
		gender: "Gender",
		placeholder_gender: "Select an option",
		email: "Email",
		placeholder_email: "Email",
		country: "Country",
		placeholder_country: "Select an option",
		region: "Province",
		placeholder_region: "Select an option",
		phone: "Phone",
		placeholder_phone: "Phone",
		number_phone: "Telephone number",
		placeholder_number_phone: "Telephone number",
		when_start: "When would you like to start your studies?",
		placeholder_when_start: "Select an option",
		privacy_policy: `<p>I have read and accept the information on the processing of <a href="/en/privacy-policy/" target="_blank" rel="noopener">personal data</a>.</p>`,
		data_protection: "Basic information on data protection:",
		data_protection_text: `**Responsable:** CUNEF S.L.U.\t
Finalidad:  Resolver las consultas y dudas formuladas por los usuarios.\t
Derechos:   En las condiciones legales, tiene derecho a acceder, rectificar y suprimir los datos, a la limitación de su tratamiento, a oponerse al mismo y a su portabilidad.\t
Información adicional:  Puede consultar la información adicional y detallada sobre Protección de Datos en el siguiente link.
	 	`,
		send_academic_information: `I agree to receive commercial and academic communications from CUNEF. <a href="/politica-de-privacidad/" target="_blank" rel="noopener">See additional information</a>.`,
		contact_by_whatsapp:
			"I authorise CUNEF to contact me and send me information via WhatsApp.",
		study_type: "Type of study",
		placeholder_study_type: "Select an option",
		study_type_options: {
			degree: "Undegraduate",
			postgraduate: "Graduate",
			minors: "Minor",
			lifelongLearning: "Executive Education",
		},
		study_area: "Areas of interest",
		placeholder_study_area: "Select an option",
		study_area_options: {
			company_economy_finance: "Business / Economics / Finance",
			data_ia: "Data / AI",
			engineering: "Engineering",
			international_institutions_law: "Law / International Institutions",
		},
		errors: {
			required: "Required field",
		},
		process_form: "Sending form…",
		title_submitted: "Thank you for your interest",
		content_submitted: "We will contact you as soon as possible..",
		reset_form: "Close and return",
		selectACategory: "Select a category",
		filterBy: "Filter by",
		select_program: "What would you like to study?",
		placeholder_select_program: "Select an option",
		type_identity_card: "Type of document?",
		placeholder_type_identity_card: "Type of document?",
		options_type_identity_card: {
			DNI: "DNI",
			Passport: "Passport",
			NIE: "NIE",
			Other: "Other",
		},
		identity_card: "Document number",
		placeholder_identity_card: "Document number",
		date_birth: "Date of birth",
		placeholder_date_birth: "Date of birth",
		accompanying_persons_tickets: "Accompanying persons tickets",
		placeholder_accompanying_persons_tickets: "Accompanying persons tickets",
		grade_candidate_entries: "Degree candidate entries",
		placeholder_grade_candidate_entries: "Degree candidate entries",
	},
	components: {
		pagination: {
			previous: "Previous",
			next: "Next",
		},
		ods: {
			svg: "EN",
			health_and_wellBeing: "Good health and well-being",
			no_poverty: "No poverty",
			zero_hunger: "Zero hunger",
			quality_education: "Quality education",
			gender_equally: "Gender equally",
			clear_water_and_sanitation: "Clear water and sanitation",
			affordable_and_clean_energy: "Affordable and clean energy",
			decent_work_and_economic_growth: "Decent work and economic growth",
			industry_innovation_and_infrastructure:
				"Industry innovation and infrastructure",
			reduced_inequalities: "Reduced inequalities",
			sustainable_cities_and_communities: "Sustainable cities and communities",
			responsible_consumption_and_production:
				"Responsible consumption and production",
			climate_action: "Climate action",
			life_below_water: "Life below water",
			life_on_land: "Life on land",
			peace_justice_and_strong_institutions:
				"Peace, justice and strong institutions",
			partnership_for_the_goals: "Partnership for the goals",
		},
	},
	modules: {
		socialShare: {
			share: "Share",
		},
		locationMap: {
			goToWebsite: "Go to website",
		},
		carousel: {
			previousSlide: "Go to previous slide",
			netSlide: "Go to next slide",
		},
		landingButtonsProgram: {
			cta: "Request Information",
		},
		eventDistributor: {
			form: {
				event_category: "Event category",
			},
		},
	},
	templates: {
		professorDetail: {
			office: "Office",
			phd: "PhD",
			departments: "Departments",
		},
		programDetail: {
			heading: "Description",
			programType: "Programme",
			learningOption: "Delivery mode",
			language: "Language",
			credits: "Credits",
			startAdmission: "Start admission",
			downloadBrochure: "Download brochure",
			places: "Places",
		},
		eventDetail: {
			date: "Date",
			place: "Place",
			hour: "Hour",
			format: "Format",
			addCalendar: "Add to calendar",
			highlightedEvents: "Highlighted events",
		},
	},
};

export default en_GB;
